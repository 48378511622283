import React from 'react';
import AboutUs from '../components/AboutUs';
import AddressBanner from '../components/AddressBanner';
import AutoService from '../components/AutoService';
import CabSection from '../components/CabSection';
import MyGoogleMap from '../components/GoogleMap';
import Hero from '../components/Hero';
import InfoSection from '../components/InfoSection';
import { Container } from '../styles/HomeContainer';

const Home = () => {
  return (
    <>
      <Container>
        <Hero />
        <AddressBanner />
        <AboutUs />
        <InfoSection />
        <CabSection />
        <AutoService />
        <MyGoogleMap />
      </Container>
    </>
  );
};

export default Home;
