import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStyle } from './styles/globalStyles';

import Home from './Pages';
import NavBar from './components/Navbar';
import Footer from './components/Footer';
function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <NavBar />
        <Home />
        <Footer />
      </Router>
    </>
  );
}

export default App;
