import React from 'react';

import {
  AutoSection,
  SectionContainer,
  ColumnLeft,
  ColumnRight,
} from './AutoServiceElements';
import AutoImg from '../../images/Auto.jpg';

const AutoService = () => {
  return (
    <AutoSection>
      <SectionContainer>
        <ColumnLeft>
          <img src={AutoImg} alt='car' />
        </ColumnLeft>
        <ColumnRight>
          <h1>Tinian Tire, Lube & Auto Wash</h1>
          <p>
            We want you to have the best service possible. Our technicians are
            ready and waiting to keep your car in the best shape we can, and
            we’ll get you up and driving around the beautiful island in no time.
          </p>
        </ColumnRight>
      </SectionContainer>
    </AutoSection>
  );
};

export default AutoService;
