import styled from 'styled-components';
import { FaInstagram, FaFacebookSquare } from 'react-icons/fa';

import { ImArrowUp } from 'react-icons/im';

export const FooterContainer = styled.footer`
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100vw;
  padding: 1rem calc((100vw - 1300px) / 2);
  width: 100%;
  background-color: #0054a5;
  color: white;
  text-align: center;
`;

export const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: stretch;
`;

export const ColumnLeft = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  order: 1;

  h2 {
    font-size: clamp(0.25rem, 3vw, 2rem);
    margin-bottom: 0.5rem;
  }

  p {
    font-size: clamp(0.2rem, 3vw, 1.1rem);
    font-weight: 200;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 2rem;
  }
`;

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  order: 2;
`;

export const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-size: clamp(0.25rem, 3vw, 2rem);
  }

  @media screen and (max-width: 720px) {
    justify-content: start;
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const FbIcon = styled(FaFacebookSquare)`
  font-size: clamp(1.5rem, 3vw, 2rem);
  margin: 0.5rem 1rem;
  cursor: pointer;
`;

export const IgIcon = styled(FaInstagram)`
  font-size: clamp(1.5rem, 3vw, 2rem);
  margin: 0.5rem 1rem;
  cursor: pointer;
`;

export const ArrowWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  padding-right: 2rem;
  padding-bottom: 2rem;
  /* background: yellow; */
  @media screen and (max-width: 768px) {
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const ArrowIcon = styled(ImArrowUp)`
  font-size: clamp(3rem, 3vw, 4rem);
  cursor: pointer;
`;

export const SocialLink = styled.a`
  color: #fff;
`;
