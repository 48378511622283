import styled from 'styled-components';

export const InfoContainer = styled.div`
  width: 100%;
  /* min-height: 100vh; */
  /* padding: 1rem calc((100vw - 1300px) / 2); */
  margin-bottom: 2.5rem;
  padding: 0;
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
`;

export const ProductCard = styled.div`
  margin: 0 1rem;
  /* line-height: 1.5rem; */
  width: 300px;
`;

export const ProductImg = styled.img`
  height: 220px;
  width: 100%;
  border: 1px solid #272727;
  filter: drop-shadow(2px 3px 3px #222);
  margin-bottom: 1rem;
`;

export const ProductTitle = styled.h2`
  font-family: arial;
  font-weight: bold;
  font-size: clamp(1.5rem, 3vw, 2rem);
  color: #0054a5;
  margin-bottom: 1rem;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 400;
`;

export const ProductDesc = styled.p`
  margin-bottom: 1rem;
`;
