import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

export const Nav = styled.nav`
  background: #0054a5;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  z-index: 50;
  position: fixed;
  top: 0;
  width: 100%;

  @media screen and (min-width: 500px) {
    display: none;
    z-index: 0;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: auto 0;
  height: 100%;
  z-index: 1;
  width: 100%;
  padding: 0;
  width: 100%;
`;

export const Logo = styled(LinkR)`
  display: flex;
  /* padding-right: 2rem; */
`;

export const ImgLogo = styled.img`
  display: flex;
  height: 50px;
  object-fit: fill;
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1rem;
`;

export const AddressText = styled.p`
  color: #fff;
  font-size: 0.5rem;
  font-weight: 500;
  text-align: justify;
`;
