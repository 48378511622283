import styled from 'styled-components';

export const CabSecContainer = styled.section`
  display: flex;
  width: 100%;
  /* height: 300px; */
  margin: 0;
  padding: 0;
`;

export const SectionContainer = styled.div`
  /* padding: 1rem calc((100vw - 1300px) / 2); */
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 2fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  height: 100%;
  /* align-items: flex-start;   */
  padding: 2rem;
  order: ${({ reverse }) => (reverse ? '1' : '2')};
  border-top: 2px solid #0054a5;
  border-bottom: 2px solid #0054a5;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  h2 {
    /* margin-bottom: 1rem; */
    font-size: clamp(1.5rem, 3vw, 2rem);
    font-weight: bold;
    font-family: arial;
    color: #0054a5;
  }

  p {
    margin-top: 0.5rem;
    /* margin-bottom: 1.5rem;     */
    text-align: justify;
  }

  span {
    margin: 1rem;
  }
`;

export const CABTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: auto 0;
`;

export const CABImg = styled.img`
  height: 50px;
  object-fit: contain;
  margin: 0;
  padding: 0;
`;

export const ColumnLeft = styled.div`
  padding: 0 1rem;
  margin: auto 0;
  order: ${({ reverse }) => (reverse ? '2' : '1')};
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    order: ${({ reverse }) => (reverse ? '1' : '2')};
  }
`;
