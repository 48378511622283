import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import {
  Nav,
  NavbarContainer,
  Logo,
  ImgLogo,
  AddressContainer,
  AddressText,
} from './NavbarElements';

import LogoS from '../../images/Logo.png';

const NavBar = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <Nav>
      <NavbarContainer>
        <Logo to='/' onClick={toggleHome}>
          <ImgLogo src={LogoS} alt='Logo' />
        </Logo>
        <AddressContainer>
          <AddressText>
            • Open Monday - Saturday: 10AM TO 6PM & Closed on Sunday
          </AddressText>
          <AddressText>• Phone: (670)433-0046</AddressText>
          <AddressText>• Corner Canal St. San Jose Tinian CNMI</AddressText>
        </AddressContainer>
      </NavbarContainer>
    </Nav>
  );
};

export default NavBar;
