import React from 'react';
import ReactPlayer from 'react-player';

import {
  CabSecContainer,
  SectionContainer,
  ColumnLeft,
  ColumnRight,
  CABTitleWrapper,
  CABImg,
} from './CabSectionElements';

import CABLogoImg from '../../images/CAB_Logo.jpg';

const CabSection = () => {
  return (
    <CabSecContainer>
      <SectionContainer>
        <ColumnLeft>
          <ReactPlayer
            url='video/CAB_Vid.mp4'
            width='100%'
            height='100%'
            controls={true}
          />
        </ColumnLeft>
        <ColumnRight>
          <CABTitleWrapper>
            <CABImg src={CABLogoImg} alt='logo' />
            <h2>Certified Angus Beef ®</h2>
          </CABTitleWrapper>
          <p>
            Certified Angus beef brand’s standards have become the true mark of
            quality. No other brand of beef is as juicy, as tender or as full of
            flavor. Look for the Certified Angus Beef brand logo at Triple J
            Wholesale To Everyone!
          </p>
          <span></span>
          <h2>Frozen Goods</h2>
          <p>
            We strive to bring quality products at the best prices on island for
            your family!
          </p>
        </ColumnRight>
      </SectionContainer>
    </CabSecContainer>
  );
};

export default CabSection;
