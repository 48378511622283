import React from 'react';
import {
  Section,
  SectionContainer,
  ColumnLeft,
  ColumnRight,
} from './AboutUsElements';
import AboutImg from '../../images/About.jpg';

const AboutUs = () => {
  return (
    <Section>
      <SectionContainer>
        <ColumnLeft>
          <h1>About Us</h1>
          <p>
            Our main products inventoried are designed to improve the lifestyle
            within the households and the yards of the people of Tinian. Our
            products are those that are most needed for convenience and to ease
            the daily chores of the home. We also will offer automobiles and
            automobile service to include oil change, tire servicing and
            automobile washing.
          </p>
        </ColumnLeft>
        <ColumnRight>
          <img src={AboutImg} alt='buildg' />
        </ColumnRight>
      </SectionContainer>
    </Section>
  );
};

export default AboutUs;
