import styled from 'styled-components';
import BannerImg from '../../images/Hero.jpg';
import BannerSmall from '../../images/HeroSmall.jpg';

export const HeroContent = styled.div`
  display: flex;
  background: url(${BannerImg});
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 300px;
  /* max-height: 350px; */
  /* overflow: hidden; */
  margin: 0;
  padding: 0;

  @media screen and (max-width: 768px) {
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.5)
      ),
      url(${BannerSmall});
    height: 250px;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const SectionContainer = styled.div`
  /* padding: 1rem calc((100vw - 1300px) / 2); */
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ColumnLeft = styled.div`
  order: 1;

  @media screen and (max-width: 768px) {
    order: 2;
    display: none;
  }
`;

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5rem;
  order: 2;
  text-align: justify;

  @media screen and (max-width: 768px) {
    order: 1;
  }
`;

export const HeroLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* align-items: flex-end; */
  width: 100%;
`;

export const HeroLogo = styled.img`
  height: 150px;
  width: 100%;
  object-fit: contain;

  /* @media screen and (max-width: 1200px) {
    height: 100px;
    width: 125px;
  } */
`;

export const HeroBannerInfo = styled.p`
  color: #fff;
  font-size: clamp(0.5rem, 2vw, 1.25rem);
  font-style: italic;
  line-height: 1.5rem;
  margin-top: 1rem;
  padding: 0;

  @media screen and (max-width: 780px) {
    line-height: 1rem;
    /* font-size: 0.75rem; */
    font-weight: lighter;
  }
`;
