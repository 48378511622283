import styled from 'styled-components';

export const Section = styled.section`
  display: flex;
  width: 100%;
  /* height: 600px; */
  padding: 4rem 0rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 0;
  }

  @media screen and (max-width: 500px) {
    margin-top: 2rem;
  }
`;

export const SectionContainer = styled.div`
  padding: 1rem calc((100vw - 1300px) / 2);
  display: grid;
  grid-template-columns: 1fr 2fr;
  /* margin-bottom: 10px; */
  /* grid-template-rows: 600px; */

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  line-height: 1.4;
  padding: 1rem 2rem;
  order: ${({ reverse }) => (reverse ? '2' : '1')};

  h1 {
    margin-bottom: 1rem;
    font-size: clamp(2rem, 3vw, 2.5rem);
    font-weight: bolder;
    font-family: Arial;
    color: #0054a5;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    text-align: justify;
  }

  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }
`;

export const ColumnRight = styled.div`
  padding: 1rem 2rem;
  order: ${({ reverse }) => (reverse ? '1' : '2')};
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    order: ${({ reverse }) => (reverse ? '2' : '1')};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: drop-shadow(1px 1px 2px #222);
    /* @media screen and (max-width: 768px) {
      width: 90%;
      height: 90%;
    } */
  }
`;
