import styled from 'styled-components';

export const MapContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* padding: 2rem calc((100vw - 1200px) / 2); */
  width: 100%;
  height: 400px;
  z-index: 100;
  padding: 1rem 0;
  /* background-color: red; */
`;
