import React from 'react';
import {
  InfoContainer,
  ProductWrapper,
  ProductCard,
  ProductImg,
  ProductInfo,
  ProductTitle,
  ProductDesc,
} from './InfoElements';

import AppliancesImg from '../../images/Appliances.JPG';
import HouseholdImg from '../../images/Household.JPG';
import OutdoorImg from '../../images/Outdoor.jpg';

const InfoSection = () => {
  return (
    <InfoContainer>
      <ProductWrapper>
        <ProductCard>
          <ProductImg src={AppliancesImg} alt='appliances' />
          <ProductInfo>
            <ProductTitle>Appliances</ProductTitle>
            <ProductDesc>
              We can help you with appliances that make life easier!
            </ProductDesc>
          </ProductInfo>
        </ProductCard>
        <ProductCard>
          <ProductImg src={HouseholdImg} alt='appliances' />
          <ProductInfo>
            <ProductTitle>Household Items</ProductTitle>
            <ProductDesc>
              Find great deals on everything from household essentials to easy
              kitchen solutions!
            </ProductDesc>
          </ProductInfo>
        </ProductCard>
        <ProductCard>
          <ProductImg src={OutdoorImg} alt='appliances' />
          <ProductInfo>
            <ProductTitle>Outdoor Items</ProductTitle>
            <ProductDesc>
              Find super-fun equipment & activities for every lifestyle and
              budget!
            </ProductDesc>
          </ProductInfo>
        </ProductCard>
      </ProductWrapper>
    </InfoContainer>
  );
};

export default InfoSection;
