import styled from 'styled-components';

export const AddressContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0;
  background-color: #b18f6c;
  color: #fff;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: none;

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const AddressText = styled.p`
  font-weight: 300;
  margin: auto 0;
  padding: 0.5rem 0;
  margin-right: 0.5rem;
  font-size: clamp(0.25rem, 3vw, 1rem);
`;

export const SocialContent = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
`;

export const IconsContainer = styled.span`
  display: flex;
  margin: auto 0;
  font-size: clamp(0.5rem, 3vw, 1.5rem);
`;
