import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import {
  FooterContainer,
  FooterWrapper,
  ColumnLeft,
  ColumnRight,
  SocialContainer,
  SocialWrapper,
  FbIcon,
  IgIcon,
  ArrowWrapper,
  ArrowIcon,
  SocialLink,
} from './FooterElement';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrapper>
        <ColumnLeft>
          <h2>Phone: (670)433-0046</h2>
          <p>
            • Open Monday-Saturday 10AM to 6PM and Closed on Sunday • Corner
            Canal St. San Jose Tinian CNMI
          </p>
        </ColumnLeft>
        <ColumnRight>
          <SocialContainer>
            <h2>Connect with Us</h2>
            <SocialWrapper>
              <SocialLink
                href='https://www.facebook.com/WholesaletoEveryoneTinian/'
                target='_blank'
                rel='noreferrer'
              >
                <FbIcon />
              </SocialLink>
              <SocialLink
                href='https://www.instagram.com/wholesaleforeveryonecom/'
                target='_blank'
                rel='noreferrer'
              >
                <IgIcon />
              </SocialLink>
            </SocialWrapper>
          </SocialContainer>
        </ColumnRight>
      </FooterWrapper>
      <ArrowWrapper onClick={toggleHome}>
        <ArrowIcon />
      </ArrowWrapper>
    </FooterContainer>
  );
};

export default Footer;
