import styled from 'styled-components';

export const AutoSection = styled.div`
  display: flex;
  width: 100%;
  /* height: 300px; */
  margin: 0;
  padding: 0;
  margin-bottom: 50px;

  @media screen and (max-width: 780px) {
    margin: 0;
  }
`;

export const SectionContainer = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: 2fr 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ColumnLeft = styled.div`
  padding: 1rem;
  order: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 768px) {
    order: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: drop-shadow(1px 1px 2px #222);
    /* @media screen and (max-width: 768px) {
      width: 90%;
      height: 90%;
    } */
  }
`;

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0.5rem;
  /* line-height: 1.4; */
  order: 2;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  h1 {
    /* margin-bottom: 1rem; */
    font-size: clamp(1.5rem, 3vw, 2.5rem);
    font-weight: bolder;
    font-family: Arial;
    color: #0054a5;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    text-align: justify;
  }
`;
