import React from 'react';
import { FaInstagram, FaFacebookSquare } from 'react-icons/fa';
import {
  AddressContainer,
  AddressText,
  SocialContent,
  IconsContainer,
} from './AddressElements';

const AddressBanner = () => {
  return (
    <AddressContainer>
      <AddressText>
        • Phone: (670)433-0046 • Open Monday - Saturday: 10AM TO 6PM and Closed
        on Sunday • Corner Canal St. San Jose Tinian CNMI
      </AddressText>
      <SocialContent>
        <IconsContainer>
          <FaFacebookSquare />
        </IconsContainer>
        <AddressText>@WholesaleToEveryone</AddressText>
        <IconsContainer>
          <FaInstagram />
        </IconsContainer>
        <AddressText>@tinianwholesaletoeveryone</AddressText>
      </SocialContent>
    </AddressContainer>
  );
};

export default AddressBanner;
