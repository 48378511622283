import React from 'react';

import {
  HeroContent,
  SectionContainer,
  ColumnLeft,
  ColumnRight,
  HeroLogo,
  HeroBannerInfo,
} from './HeroElements';

import Logo from '../../images/Logo.png';

const Hero = () => {
  return (
    <HeroContent>
      <SectionContainer>
        <ColumnLeft>
          <span></span>
        </ColumnLeft>
        <ColumnRight>
          <HeroLogo src={Logo} alt='logo' />
          <HeroBannerInfo>
            The only place on Tinian where you can find amazing deals on daily
            essentials, high quality brands and must have electronics!
          </HeroBannerInfo>
        </ColumnRight>
      </SectionContainer>
    </HeroContent>
  );
};

export default Hero;
